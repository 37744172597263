@import "node_modules/bootstrap/scss/functions"; // necessary because _assert-ascending and assert-starts-at-zero are used in the variables file

// grays
$white: #fff;
$gray-050: #f6f6f6; //Table-Header
$gray-100: #e5e5e5; // ORCA: Gray, Usage: Trennlinien
$gray-200: #eaeaea; // ORCA: Hover Gray, Usage:  Hover Katalog Items, Hover Tree Items, Rahmen hell, Gruppen Überschriften
$gray-300: #dee2e6; // ORCA: former searchbar-border color
$gray-400: #bbb; // ORCA: Light, Usage:  Rahmen Standard
$gray-500: #6c757d; // ORCA: Blue Gray, Usage: Teasertext, Text Muted
$gray-600: #616161; // ORCA: Medium Gray, Usage: Tooltip
$gray-700: #495057;
$gray-750: #474646; // ORCA: Merklisten header
$gray-800: #373737; // ORCA: Dark, Usage: Font Standard, Icon Standard
$gray-900: #212529;

// base-colors
$black: #000;
$blue: #014c8c;
$indigo: #6610f2;
$purple: #f0f;
$pink: #e83e8c;
$red: #ff4000;
$orange: #ffa500;
$orange-light: #ffe6c1;
$yellow: #ffc72c; //ORCA: ADE-Gelb, Usage: Header Background, Button Rahmen
$green: #73af71; // ORCA
$green-light: #cdf3d2;
$teal: #20c997;
$cyan: #0688db; // ORCA: Cyan
$cream: #fff0b6;
$magenta: #c71252;
$magenta-light: #ffcdcd;
$magenta-medium: #f22d74;
$marked: #ffd769;
$dark-blue: #63879d;
$light-blue: #e6eff5;
$transparent: transparent;

// themes
$primary: $yellow;
$secondary: $cream;
$success: $green;
$success-light: $green-light;
$info: $cyan;
$warning: $orange;
$warning-light: $orange-light;
$danger: $magenta;
$danger-light: $magenta-light;
$danger-medium: $magenta-medium;
$hover: $gray-200;
$light: $gray-400;
$dark: $gray-800;
$catalog-manager: $dark-blue;
$catalog-manager-light: $light-blue;
$blank: $white;

// Charts
$series-a: #c1e2f5;
$series-b: #97ceed;
$series-c: #51a3d4;
$series-d: #176e9f;
$series-e: #124c73;
$series-f: $primary;
$chart-neutral-plus: #8dafc8;
$chart-neutral: #408dbb;
$chart-benchmark: $primary;

$colors: ();
$colors: map-merge(
	(
		"blue": $blue,
		"indigo": $indigo,
		"purple": $purple,
		"pink": $pink,
		"red": $red,
		"orange": $orange,
		"yellow": $yellow,
		"green": $green,
		"teal": $teal,
		"cyan": $cyan,
		"white": $white,
		"gray": $gray-600,
		"gray-dark": $gray-800,
		"cream": $cream,
		"magenta": $magenta,
		"green-light": $green-light,
		"orange-light": $orange-light,
		"magenta-light": $magenta-light,
		"magenta-medium": $magenta-medium,
		"black": $black,
		"gray-050": $gray-050,
		"series-a": $series-a,
		"series-b": $series-b,
		"series-c": $series-c,
		"series-d": $series-d,
		"series-e": $series-e,
		"series-f": $series-f,
		"chart-benchmark": $chart-benchmark,
		"chart-neutralPlus": $chart-neutral-plus,
		"chart-neutral": $chart-neutral,
		"transparent": $transparent,
	),
	$colors
);

$theme-colors: ();
$theme-colors: map-merge(
	(
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"success-light": $success-light,
		"info": $info,
		"warning": $warning,
		"warning-light": $warning-light,
		"danger": $danger,
		"danger-light": $danger-light,
		"danger-medium": $danger-medium,
		"hover": $hover,
		"light": $light,
		"dark": $dark,
		"blank": $blank,
		"tertiary": $warning-light,
		"error": $danger,
		"inverse": $danger-medium,
		"marked": $marked,
		"catalog-manager": $catalog-manager,
		"catalog-manager-light": $catalog-manager-light,
		"black": $black,
		"gray-050": $gray-050,
		"gray-100": $gray-100,
		"gray-200": $gray-200,
		"gray-300": $gray-300,
		"gray-400": $gray-400,
		"gray-500": $gray-500,
		"gray-600": $gray-600,
		"gray-700": $gray-700,
		"gray-750": $gray-750,
		"gray-800": $gray-800,
		"gray-900": $gray-900,
	),
	$theme-colors
);

// Link Elemente
$link-decoration: none;
$link-hover-color: darken($primary, 15%);
$link-hover-decoration: underline;

// stylelint-disable value-keyword-case
$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans",
	sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
	"Noto Color Emoji" !default;
$font-family-base: Roboto, Arial, Helvetica, sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the value set on body in custom.css
$font-size-lg: $font-size-base * 1.29;
$font-size-sm: $font-size-base * 0.86;
$font-size-lgr: $font-size-base * 1.2;
$font-size-smr: $font-size-base * 0.93;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-tooltip: 2040;
$zindex-modal-backdrop: 2050;
$zindex-modal: 2060;
$zindex-popover: 2070;
$zindex-toast: 2080;

$modal-backdrop-bg: #fff;
$tooltip-bg: $gray-600;
$modal-header-padding-y: 0.5rem;
$modal-header-padding-x: 1rem;

$border-radius: 0.25rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.1rem;

// Orca Anpassung dropdown padding
$dropdown-padding-y: 0;

// Orca badge anpassung z.B. bei globalen suche filter oder schlagwortapp
$badge-border-radius: 10rem;
$badge-padding-y: 0.25em;

// nicht bootstrap variablen
$one-rem: 14px; // ORCA Defintion of 1rem
$zindex-unset: unset;
$zindex-feedback: 1900;
$zindex-usermenu-plus: 2071;
$zindex-user-menu: 3000;
$zindex-user-menu-tooltip: 3001;
$zindex-fullscreen: 99999999;
