// Bitte CSS-Klassen für Url-Aufrufe bei Masking  nicht mehr hier global hinterlegen, sondern in den jeweiligen SCSS Dateien der jeweiligen Komponenten
// Masking Klassen bitte nach Möglichkeit zurück zu dem Komponenten auflösen

.mask-settings {
	mask-position: center !important;
	mask-repeat: no-repeat !important;
	mask-size: calc(100% - 2px) !important;
	mask-type: alpha;
	touch-action: manipulation;
}

.grid-mask-img {
	height: 1.8rem;
	width: 1.8rem;
}

.size-1-2rem {
	height: 1.2rem;
	width: 1.2rem;
}

.size-1-3rem {
	height: 1.3rem;
	width: 1.3rem;
}

.size-1-4rem {
	height: 1.42857143rem;
	width: 1.42857143rem;
}

.size-1-5rem {
	height: 1.5rem;
	width: 1.5rem;
}

.size-2rem {
	height: 2rem;
	width: 2rem;
}

.size-2-4rem {
	height: 2.4rem;
	width: 2.4rem;
}

.modal-close {
	height: 1.5rem;
	width: 1.5rem;
}

.warning-img-size {
	height: 1.4rem;
	width: 1.4rem;
}

.delete-mask-img {
	mask: url("/assets/orca/global/delete.svg");
}

.delete-forever-mask-img {
	mask: url("/assets/material/delete_forever.svg");
}

.edit-mask-img {
	mask: url("/assets/material/edit.svg");
}

.save-mask-img {
	mask: url("/assets/material/save-24px.svg");
}

.cancel-mask-img {
	mask: url("/assets/material/round-clear-grey.svg");
}

.add-mask-img {
	mask: url("/assets/material/outline_note_add.svg");
}

.add-circle-mask-img {
	mask: url("/assets/material/add_circle_outline-24px.svg");
}

.clipboards-mask-img {
	mask: url("/assets/material/clipboards.svg");
}

.download-mask-img {
	mask: url("/assets/material/download-yellow.svg");
}

.present-to-all-mask-img {
	mask: url("/assets/material/baseline-present_to_all-yellow.svg");
}

.baseline-link-mask-img {
	mask: url("/assets/material/baseline-link-yellow.svg");
}

.baseline-launch-mask-img {
	mask: url("/assets/material/baseline-launch-white.svg");
}

.link-mask-img {
	mask: url("/assets/material/link-24px.svg");
}

.link-off-mask-img {
	mask: url("/assets/material/link_off-24px_grey.svg");
}

.outline-cloud-upload-mask-img {
	mask: url("/assets/material/outline-cloud_upload-24px.svg");
}

.close-mask-img {
	mask: url("/assets/material/round-clear-grey.svg");
}

.check-mask-img {
	mask: url("/assets/material/check-24px.svg");
}

.info-mask-img {
	mask: url("/assets/material/info-white.svg");
}

.plus-mask-img {
	mask: url("/assets/material/add-24px.svg");
}

.minus-mask-img {
	mask: url("/assets/material/remove-24px.svg");
}

.fit-mask-img {
	mask: url("/assets/material/zoom_out_map-24px.svg");
}

.replay-mask-img {
	mask: url("/assets/material/replay-24px.svg");
}

.arrowdown-mask-img {
	mask: url("/assets/material/arrow_downward-24px.svg");
}

.arrowup-mask-img {
	mask: url("/assets/material/arrow_upward-24px.svg");
}

.keyboard-arrowup-mask-img {
	mask: url("/assets/material/keyboard_arrow_up-24px.svg");
}

.keyboard-arrowdown-mask-img {
	mask: url("/assets/material/keyboard_arrow_down-24px.svg");
}

.baseline-sort-grey-arrowdown-mask-img {
	mask: url("/assets/material/baseline-sort-grey.svg");
}

.baseline-done-grey-arrowdown-mask-img {
	mask: url("/assets/material/baseline-done-grey.svg");
}

.eye-mask-img {
	mask: url("/assets/material/remove_red_eye-24px.svg");
}

.dashboard-mask-img {
	mask: url("/assets/material/dashboard.svg");
}

.menubook-mask-img {
	mask: url("/assets/material/menu_book-24px.svg");
}

.contactmail-mask-img {
	mask: url("/assets/material/baseline-contact_mail-white.svg");
}

.baseline-launch-mask-img {
	mask: url("/assets/material/baseline-launch-black.svg");
}

.hourglass-top-mask-img {
	mask: url("/assets/material/hourglass_top_black_24dp.svg");
}

.check-circle-outline-mask-img {
	mask: url("/assets/material/check_circle_outline-24px.svg");
}

.cancel-24px-mask-img {
	mask: url("/assets/material/cancel-24px.svg");
}

.warning-mask-img {
	mask: url("/assets/orca/global/warning.svg");
}

.information-mask-img {
	mask: url("/assets/material/info-24px.svg");
}

.done-mask-img {
	mask: url("/assets/material/done-24px.svg");
}

.danger-mask-img {
	mask: url("/assets/material/cancel-24px.svg");
}

.file-copy-mask-img {
	mask: url("/assets/material/file_copy-24px.svg");
}

.share-mask-img {
	mask: url("/assets/material/actionbuttons/share-24px.svg");
}

.text-snippet-mask-img {
	mask: url("/assets/material/text_snippet-24px.svg");
}

.more-horiz-mask-img {
	mask: url("/assets/material/more_horiz-24px.svg");
}

.business-mask-img {
	mask: url("/assets/material/business-grey.svg");
}

.flag-filled-mask-img {
	mask: url("/assets/material/flag-filled-24px.svg");
}

.account-mask-img {
	mask: url("/assets/material/round-account_circle-grey.svg");
}

.orca-logo-mask-img {
	mask: url("/assets/orca/Logo_ORCA_weiß.svg");
}

.keyboard-arrow-up-flat-mask-img {
	mask: url("/assets/orca/keyboard_arrow_up-24px-flat.svg");
}

.keyboard-arrow-down-flat-mask-img {
	mask: url("/assets/orca/keyboard_arrow_down-24px-flat.svg");
}

.arrow-right-white-mask-img {
	mask: url("/assets/material/navigate_next-24px-white.svg");
}

.arrow-right-mask-img {
	mask: url("/assets/material/arrow_right-24px.svg");
}

.arrow-down-mask-img {
	mask: url("/assets/material/arrow_drop_down-24px.svg");
}

.arrow-upward-mask-img {
	mask: url("/assets/material/arrow_upward-24px.svg");
}

.arrow-downward-mask-img {
	mask: url("/assets/material/arrow_downward-24px.svg");
}

.arrow-backward-mask-img {
	mask: url("/assets/material/arrow_backward-24px.svg");
}

.navigate-before-mask-img {
	mask: url("/assets/material/navigate_before-24px.svg");
}

.navigate-next-mask-img {
	mask: url("/assets/material/navigate_next-24px.svg");
}

.help-mask-img {
	mask: url("/assets/material/help-24px.svg");
}

.tree-arrow-right-mask-img {
	mask: url("/assets/orca/catalogTree/arrow_right.svg");
}

.tree-arrow-down-mask-img {
	mask: url("/assets/orca/catalogTree/arrow_down.svg");
}

.close-cross-mask-img {
	mask: url("/assets/material/close-24px.svg");
}

.list-mask-img {
	mask: url("/assets/material/list-24px.svg");
}

.closed-folder-mask-img {
	mask: url("/assets/material/closedFolder.svg");
}

.closed-folder-plus-mask-img {
	mask: url("/assets/orca/catalogTree/closedFolderPlus.svg");
}

.searchbar-action-mask-img {
	mask: url("/assets/material/search-24px-grey.svg");
}

.mobile-searchbar-action-mask-img {
	mask: url("/assets/orca/mobile/search.svg");
}

.mobile-searchbar-filter-action-mask-img {
	mask: url("/assets/orca/mobile/search-filter.svg");
}

.mobile-searchbar-news-action-mask-img {
	mask: url("/assets/orca/catalogNews/news.svg");
}

.mobile-back-to-top {
	mask: url("/assets/orca/mobile/back-to-top.svg");
}

.infocenter-mask-img {
	mask: url("/assets/orca/news.svg");
}

.oops-mask-img {
	mask: url("/assets/material/sentiment_dissatisfied-24px.svg");
}

.orca-wal-mask-img {
	mask: url("/assets/material/orca_wal.svg");
}

.software-partner-mask-img {
	mask: url("/assets/material/Softwarepartner.svg");
}

.mail-outline-mask {
	mask: url("/assets/material/ic_mail_outline_24px.svg");
}

.open-in-new-mask-img {
	mask: url("/assets/material/open_in_new-24px_grey.svg");
}

.light-bulb-mask-img {
	mask: url("/assets/material/emoji_objects-24px.svg");
}

.date-range-mask-img {
	mask: url("/assets/material/date_range_black_24dp.svg");
}

.column-chart-mask-img {
	mask: url("/assets/material/bar_chart_black_24dp.svg");
}

.line-chart-mask-img {
	mask: url("/assets/material/show_chart_black_24dp.svg");
}

.hamburger-menu-mask-img {
	mask: url("/assets/material/hamburger-menu.svg");
}

.category-menu-mask-img {
	mask: url("/assets/material/ICN_ade next_2021_Fachbereiche.svg");
}

.catalog-menu-mask-img {
	mask: url("/assets/material/ICN_ade next_2021_Kataloge.svg");
}

.filter-list-mask-img {
	mask: url("/assets/material/filter_list-24px.svg");
}

.filter-alt-mask-img {
	mask: url("/assets/material/filter_alt_24dp.svg");
}

.visibility-img {
	mask: url("/assets/material/visibility_black_24dp.svg");
}

.visibility-off-img {
	mask: url("/assets/material/visibility_off_black_24dp.svg");
}

.description-mask-img {
	mask: url("/assets/orca/catalogTree/outline_description.svg");
}

.note-add-mask-img {
	mask: url("/assets/orca/catalogTree/outline_note_add.svg");
}

.note-mask-img {
	mask: url("/assets/orca/catalogTree/outline_note.svg");
}

.text-plus-mask-img {
	mask: url("/assets/orca/catalogTree/outline_text_plus.svg");
}

.manage-search-mask-img {
	mask: url("/assets/material/manage_search_black_24dp.svg");
}

.star-border-mask-img {
	mask: url("/assets/material/star_outline_black_24dp.svg");
}

.star-rate-mask-img {
	mask: url("/assets/material/star_rate_black_24dp.svg");
}

.document-mask-img {
	mask: url("/assets/material/document.svg");
}

.feedback-mask-img {
	mask: url("/assets/material/ICN_ade next_2021_Feedback2.svg");
}

.send-mask-img {
	mask: url("/assets/material/send_black_24dp.svg");
}

.fact-check-img {
	mask: url("/assets/material/fact_check_black_24dp.svg");
}

.orca-plus-mask-img {
	mask: url("/assets/orca/plus.svg");
}

.world-mask-img {
	mask: url("/assets/orca/searchFilter/world.svg");
}

.catalogs-mask-img {
	mask: url("/assets/orca/searchFilter/catalogs.svg");
}

.watchlist-navigate-img {
	mask: url("/assets/orca/watchlist/merkliste_dark.svg");
}

.infoletter-mask-img {
	mask: url("/assets/material/ICN_ade next_2021_Infoletter.svg");
}

.add-to-watchlist-img {
	mask: url("/assets/orca/catalogTree/merken.svg");
}

.remove-from-watchlist-img {
	mask: url("/assets/orca/catalogTree/nicht merken.svg");
}

.international-catalogs-menu-mask-img {
	mask: url("/assets/orca/catalogLists/world-mobile.svg");
}

.paint-mask-img {
	mask: url("/assets/orca/integration/paint.svg");
}

.naviswitch-mask-img {
	mask: url("/assets/orca/integration/switch.svg");
}

.analytics-mask-img {
	mask: url("/assets/material/analytics.svg");
}

.email-white-mask-img {
	mask: url("/assets/material/email-white.svg");
}

.help-outline-mask-img {
	mask: url("/assets/material/help_outline-24px.svg");
}

.contact-persons-mask-img {
	mask: url("/assets/orca/ansprechpartner.svg");
}
