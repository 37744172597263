//
// Material Icons are licensed under the Apache License, Version 2.0 (the "License");
// http://www.apache.org/licenses/LICENSE-2.0
//
// Roboto is licensed under the Apache License, Version 2.0 (the "License");
// http://www.apache.org/licenses/LICENSE-2.0
//

@import "variables-and-mixins";
@import "image-mask";

html,
body {
	color: $gray-800;
	font-size: $one-rem;
	height: 100%;
	line-height: normal;
	margin: 0;
	overflow: hidden;
	padding: 0;
	box-sizing: border-box;
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	::backdrop {
		background-color: white;
	}

	[type="button"],
	[type="reset"],
	[type="submit"],
	button {
		-webkit-appearance: unset !important;
	}
}

:root {
	// colors, themeColors and breakpoints are exported by _root.scss in node_modules/bootstrap
	// Custom variable values only support SassScript inside `#{}`.
	// Append SCSS variables which are desired to be converted to CSS-Variables to be accessible via Typescript:
	--one-rem: #{$one-rem};
}

.no-apple-customize {
	a {
		color: inherit !important;
		text-decoration: none !important;
		font-size: inherit !important;
		font-family: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
	}
}

@mixin focus-box-shadow {
	box-shadow: 0 0 0 0.2rem $cream !important;
	border-radius: 3px !important;
	z-index: $zindex-user-menu;
}

a:focus-visible,
.form-control:focus-visible,
.btn:focus-visible,
div:focus-visible,
.dropdown-item:focus-visible,
span:focus-visible {
	outline: none;
	@include focus-box-shadow;
}

html,
body,
.grid-container {
	height: 100%;
	margin: 0;
}
.grid-maincontent {
	position: relative;
}

hr {
	border-top: thin solid $gray-100;
	margin: 4px 0;
	opacity: 1;
}

// todo -> Wenn die Komponente "tagviewer" fertig entwickelt ist scss löschen!!! - START
ul.tag-list {
	margin: 0;
	padding: 0;
	width: 100%;
}

ul.tag-list li {
	@extend .m-1;
	@extend .p-2;
	@extend .rounded;
	border: 1px solid $gray-600;
	display: inline-block;
	list-style: none;
}

ul.tag-list.selectable {
	cursor: pointer;
}

ul.tag-list.selectable li:hover {
	background-color: $primary;
}

ul.tag-list.selectable li.selected {
	background-color: $primary;
	border-color: $primary;
}

// todo -> Wenn die Komponente "tagviewer" fertig entwickelt ist scss löschen!!! - END

img {
	color: transparent;
	image-rendering: auto;
}
// In Safari wird bei -webkit-optimize-contrast das Bild unscharf angezeigt. Bei Chrome dagegen schärfer als bei auto.
.MS-Edge-Chromium,
.Opera,
.Chrome {
	img {
		/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
		image-rendering: -webkit-optimize-contrast;
	}
}

.catalogLogo {
	height: auto;
	max-height: 75px;
	max-width: 125px;
}

.thumbnail-wrapper {
	@extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-center;
	@extend .p-1;
	border: 2px solid transparent;
	height: 80px;
	width: 80px;
}

// Loading animation
.loading-animation {
	left: 50%;
	margin-left: -125px;
	margin-top: -16px;
	position: absolute;
	top: 50%;
}

// Hover - START
.hover {
	background-color: $gray-800;
}

// Hover - START

// Header - START
.header {
	background-color: $primary;

	:hover {
		background-color: $white;
	}
}

// Header - END

// Image Holder - START
// height, width and flex-basis (same value as width) need to be set in the component css (e.g. catalog.component.scss)
.img-holder {
	background-color: $white;
	height: 6.01rem;
	min-width: 9.573rem;
	position: relative;
	width: 9.573rem;

	&::before,
	&::after {
		@include transition();
		background-color: $gray-300;
		content: "";
		height: 1.5rem;
		position: absolute;
		width: 1.5rem;
		z-index: 1;
	}

	&::before {
		left: 0;
		top: 0;
	}

	&::after {
		bottom: 0;
		right: 0;
	}

	.img {
		align-items: center;
		background-color: $white;
		display: flex;
		height: calc(100% - 0.28rem);
		justify-content: center;
		left: 0.143rem;
		position: absolute;
		top: 0.143rem;
		width: calc(100% - 0.28rem);
		z-index: 2;
	}

	img {
		max-height: 100%;
		max-width: 100%;
		object-fit: contain;
		@extend .logo-padding;
	}
}

.img-holder-hover:hover {
	&::before,
	&::after {
		@include transition();
		// background-color: $cream;
		height: 100%;
		width: 100%;
	}
}

.img-holder-small {
	height: 2.5rem;
	min-width: 4rem;
	width: 4rem;

	&::before,
	&::after {
		@include transition();
		height: 0.75rem;
		width: 0.75rem;
	}

	.img {
		height: calc(100% - 2px);
		left: 1px;
		top: 1px;
		width: calc(100% - 2px);
	}
}

.img-holder-medium {
	height: 5rem;
	min-width: 7rem;
	width: 7rem;

	&::before,
	&::after {
		@include transition();
		height: 1.4rem;
		width: 1.4rem;
	}

	.img {
		height: calc(100% - 2px);
		left: 1px;
		top: 1px;
		width: calc(100% - 2px);
	}
}

.img-holder-hovereffekt-wrapper {
	outline-offset: 2px;
}

.img-holder-hovereffekt-wrapper:hover .img-holder-hover {
	&::before,
	&::after {
		@include transition();
		// background-color: $cream;
		height: 100%;
		width: 100%;
	}
}

// Image Holder - END
.opacity-on-hover img {
	filter: opacity(0.25);
}

// Effekt der das Bild schwarzweiss und erst auf hover farbig darstellt
.colored-on-hover img {
	filter: grayscale(1);
}

.opacity-on-hover:hover img {
	filter: opacity(1);
}

.colored-on-hover:hover img {
	filter: grayscale(0);
}

.logo-padding {
	padding: 0.25rem;
}

.logo-margin {
	margin: 0.25rem;
}

.logo-large-size {
	width: 123px;
	height: 82px;
}

.logo-small-size {
	width: 39px;
	height: 26px;
}

// User - START
.login {
	color: $gray-800; // todo
	text-align: center;
}

.logout {
	color: $gray-800;
	text-align: center;
}

.passwortReset {
	color: $gray-800;
	text-align: center;
}

// User - END

.navigationItem {
	background-color: $white;

	:hover {
		background-color: $gray-800;
	}
}

// Navigation - END

// Blockelement für Tags, Suche, weitere Cataloge ...
.navigation-block {
	padding-left: 0.2rem;
}
.navigation-block .navigation-block-header {
	border-bottom: thin solid $gray-300;
	font-weight: $font-weight-semibold;
	padding-bottom: 0.25rem;
	margin-bottom: 0.25rem;
}

// Console - START

.console {
	background: $gray-600;
	color: $white;
	font-family: monospace;
	font-size: 0.857142857em;
	overflow-x: auto;
	overflow-y: auto;
	white-space: pre-wrap;
}

// Console - END

// scrollbox and scrollbox content use for all scrolling - START
// Zeile1: Kinder von Bootstrap Klassen wie col-12
// Zeile2: Kinder von Bootstrap Klassen wie col-12 (Klasse steht nicht vorn im class Attribut)
// Zeile3: Kinder von scrollContainer
// Zeile4: Enkel von scrollContainer

.scrollbar-space-y {
	padding-right: 17px;
}

.scrollbar-space-x {
	padding-bottom: 17px;
}

.mobile-device .scrollbar-space-y,
.mobile-device .scrollbar-space-x {
	overflow: auto !important;
}

.overflow-y-scroll {
	overflow-x: hidden;
	overflow-y: scroll;
}

.overflow-x-scroll {
	overflow-x: scroll;
	overflow-y: hidden;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.scrollbox-autohover {
	scrollbar-gutter: stable;
	overflow: auto;
	visibility: hidden;
}

.scrollbox-content-autohover,
.scrollbox-autohover:hover,
.scrollbox-autohover:focus {
	visibility: visible;
}

.scrollbox {
	overflow: scroll;
	visibility: hidden;
}

.scrollbox>*,
.scrollboxXY>*,
//.scrollbox-content,
.scrollbox:hover {
	visibility: visible;
}

.mobile-device {
	.scrollbox-autohover {
		overflow: auto;
		visibility: visible;
	}

	.scrollbox {
		overflow: scroll;
		visibility: visible;
	}
}

.scrollContainer {
	position: relative;
}

// scrollbox and scrollbox content use for all scrolling - END

.hoverable-border:hover,
.hoverable-border.selected {
	border-color: $primary;
}

// Loading animation
.loading-animation {
	left: 50%;
	margin-left: -125px;
	margin-top: -16px;
	position: absolute;
	top: 50%;
}

// Global Utilities
.cursor-pointer {
	cursor: pointer;
}

.cursor-text {
	cursor: text;
}

.cursor-default {
	cursor: default;
}

.filter-collapsed {
	height: 0 !important;
	overflow: hidden;
	right: 5px;
	transform: translateY(-100%);
	top: 100%;
}

.form-warning {
	svg-icon {
		svg {
			height: 1.3rem;
			width: 1.3rem;
		}
	}
}

.form-width {
	max-width: 50rem;
}

.input-required::after {
	content: "*";
}

.ngbTooltipClass {
	z-index: $zindex-tooltip;
}

.tooltip-user-menu {
	z-index: $zindex-user-menu-tooltip;
}

.user-menu-plus {
	z-index: $zindex-usermenu-plus !important;
}

// Schriftgrößen
.text-large {
	font-size: $font-size-lg;
}
.text-larger {
	font-size: $font-size-lgr;
}
.text-normal {
	font-size: $font-size-base;
}
.text-smaller {
	font-size: $font-size-smr;
}
.text-small {
	font-size: $font-size-sm;
}
.text-hint {
	font-size: $font-size-base * 0.75;
}

.text-big-mobile {
	font-size: $h2-font-size;
}

.text-big {
	font-size: $h1-font-size;
}

.linebreak-after-backslash-n {
	white-space: pre-line;
}

// Zeilenhöhen
.line-height-small {
	line-height: $line-height-base * 0.63333333; // ergibt: 1
}

.line-height-smaller {
	line-height: $line-height-base * 0.83333333; // ergibt: 1,25
}

.line-height-medium {
	line-height: $line-height-base; // ergibt: 1,5
}

.line-height-larger {
	line-height: $line-height-base * 1.16666667; // ergibt: 1,75
}

.line-height-large {
	line-height: $line-height-base * 1.33333333; // ergibt: 2
}

// Allgemeine Klassen um Text ein-, zwei- oder dreizeilig zu machen - START
.one-line {
	/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-box-orient: vertical;
	display: -webkit-box;
	/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-line-clamp: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-all;
}

.two-lines {
	/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-box-orient: vertical;
	display: -webkit-box;
	/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	hyphens: auto;
	word-wrap: break-word;
	word-break: break-word;
}

.three-lines {
	/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-box-orient: vertical;
	display: -webkit-box;
	/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */
	-webkit-line-clamp: 3;
	overflow: hidden;
	text-overflow: ellipsis;
	hyphens: auto;
	word-wrap: break-word;
	word-break: break-word;
}
// Allgemeine Klassen um Text ein-, zwei- oder dreizeilig zu machen - END

.use-hyphens {
	hyphens: auto;
}

/* Styles für die Controls aller Viewer (Bilder, PDF, IFC) */
.viewer-nav-control-stick-to-top {
	margin-top: -#{$border-width};
}

// Dropdowns im Export Dialog
.dropdown-menu {
	box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
}

// SVG -Icons - START

// fill-class to change colour of svg icons

// SVG -Icons - END

// Max Width Helpers - START
.mwidth-35rem {
	max-width: 35rem;
}

.mwidth-70rem {
	max-width: 70rem;
}

.icon-default {
	flex-shrink: 0;
	width: 1.4rem;
	height: 1.4rem;
}

.mwidth-tiles-container {
	max-width: 135rem;
}

// Max Width Helpers - END

// Min Width Helpers - START
.miwidth-1100px {
	min-width: 1100px;
}
// Min Width Helpers - END

// Max Height Helpers - START

.mheight-100 {
	max-height: 100%;
}

// Max Height Helpers - START

//
.badge-numbers {
	background-color: $white;
	border: 1px solid $primary;
	color: $dark;
	line-height: 1;
}

.grid-control-img {
	height: 2rem;
	width: 2rem;
}

// Action Button for orca admin tables - START
.table-action {
	&-btn {
		img {
			background-color: $gray-400;
		}

		&:disabled img {
			background-color: $gray-100;
		}

		&:not(:disabled):not(.disabled):hover img {
			background-color: $dark;
		}

		&-dropdown {
			position: absolute;
			right: 0;
			width: max-content;
			z-index: $zindex-dropdown;
		}
	}

	&-dropdown-btn {
		img {
			background-color: $dark;
		}

		&:disabled img {
			background-color: $gray-100;
		}
	}
}

// Action Button for orca admin role table - END

// Overwrite Disabled Buttons
.btn.disabled,
.btn:disabled,
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:disabled {
	background-color: $hover;
	border-color: $hover;
	color: $gray-500;
}

// Arrows for Homepage - START
.home-arrow-left {
	position: relative;
	right: 48.5%;
	height: 7rem;
}

.home-arrow-right {
	left: 48.5%;
	position: relative;
	height: 7rem;
}

.break-word {
	word-break: break-word;
}
// Arrows for Homepage - END

.help-flyout {
	background: $white;
	box-shadow: 0 6px 8px -3px $gray-800;
	height: calc(100% + 40px);
	position: absolute;
	right: 0;
	top: -46px;
}

// Custom Chart Styles - START
.chart-sum {
	color: $headings-color;
	font-family: $headings-font-family;
	font-size: $h1-font-size;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	margin-bottom: $headings-margin-bottom;
}

.chart-height {
	height: 600px;
}
// Custom Chart Styles - END

// Custom Dashboard Styles - START
.dashboard-selectbox-width {
	width: 16rem;
}

.teaser-highlight {
	font-weight: bold;
}

.tile {
	flex: 1;
	padding-bottom: 2rem;
	padding-right: 2rem;
}
// Custom Dashboard Styles - END

.m-unset {
	margin: unset !important;
}

.pre-wrap {
	white-space: pre-wrap;
}

.tooltip-inner {
	width: auto;
	hyphens: auto;
	max-width: 20rem;
	text-align: left;
}

.nohyphens .tooltip-inner {
	hyphens: none;
}

// Übernommen aus https://www.w3.org/WAI/tutorials/forms/labels/ wegen SEO
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.below-searchbar {
	.modal-content {
		margin-top: 4.3rem;
	}
}

.popup {
	.modal-content {
		border: 0;
		background-color: $gray-200;
		padding: map-get($spacers, 3);
	}
}

.popup-backdrop {
	opacity: 0 !important;
}

.max-vw-100 {
	max-width: 100vw;
}

.max-w-100 {
	max-width: 100%;
}

.fullscreen:fullscreen {
	max-height: 100vh;
	max-width: 100vw;
	overflow: auto;
}

.fullscreen::backdrop {
	background-color: black;
}

.viewer-base-height {
	height: 100px !important;
}

/* htmlViewer auf mobile in der Breite beschränken */
@media screen and (max-width: 283pt) {
	#htmlViewer p {
		width: 100% !important;
	}
}
/**/

/*
	Set input field font-size to 16px to avoid automatic zoom on edit
	iPhone / Safari
*/
@supports (-webkit-touch-callout: none) {
	@media screen and (max-width: 375pt) {
		input[type="text"] {
			font-size: 16px !important;
		}
	}
}

.left-0 {
	left: 0;
}

.right-0 {
	right: 0;
}

.link-disabled {
	pointer-events: none;
	cursor: default;
	color: $dark;
}

header {
	display: flex;
	align-items: center;
	height: 55px;
}

ngb-tooltip,
ngb-tooltip-window,
ngb-tooltip-window * {
	pointer-events: none !important;
}

.modal-heigher-md {
	@media (min-width: map-get($container-max-widths, "md")) {
		min-width: 43rem;
	}
}

.modal-big {
	@media (min-width: map-get($container-max-widths, "sm")) {
		max-width: 90vw;
	}
}

.modal-nobreak-big {
	@media (max-width: map-get($container-max-widths, "xl")) {
		max-width: 95vw;
	}
}

.mobile-device {
	.btn.btn-outline-primary:not(:disabled):not(.disabled):hover:not(.active) {
		background-color: $white;
	}
}

// workaround wegen fehlerhafter Darstellung von icons in ccm19
// TODO jw: Von Zeit zu Zeit prüfen und ggf. entfernen
img.ccm-settings-summoner--icon {
	display: none;
}

.new-items {
	background: $magenta;
	width: 1.3rem;
	height: 1.3rem;
	border-radius: 50%;
	padding-top: 1px;
}

.no-pointer-event {
	pointer-events: none;
}

.btn-group,
.btn-group-vertical {
	display: inline-flex;
	position: relative;
	vertical-align: middle; // match .btn alignment given font-size hack above

	.btn {
		flex: 1 1 auto;
		position: relative;

		// Bring the hover, focused, and "active" buttons to the front to overlay
		// the borders properly

		&:hover,
		&:focus,
		&:active,
		&.active {
			z-index: 1;
		}
	}
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.input-group {
		width: auto;
	}
}

.btn-group {
	// rules if buttons are direct children of button group

	// Prevent double borders when buttons are next to each other
	> .btn:not(:first-child),
	> .btn-group:not(:first-child) {
		margin-left: -$btn-border-width;
	}

	// Reset rounded corners
	> .btn:not(:last-child):not(.dropdown-toggle),
	> .btn-group:not(:last-child) > .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	> .btn:not(:first-child),
	> .btn-group:not(:first-child) > .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	// rules if buttons are not direct children of button group
	// Prevent double borders when buttons are next to each other
	> *:not(:first-child) .btn,
	> *:not(:first-child) .btn-group {
		margin-left: -$btn-border-width;
	}

	// Reset rounded corners
	> *:not(:last-child) .btn:not(.dropdown-toggle),
	> *:not(:last-child) .btn-group > .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	> *:not(:first-child) .btn,
	> *:not(:first-child) .btn-group > .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.btn.btn-outline-primary {
	color: $dark;

	// &.disabled,
	// &:disabled {
	// 	border-color: $dark;
	// 	opacity: 1;
	// }

	&:not(:disabled):not(.disabled):hover {
		background-color: $primary;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: $primary;
		border-color: $primary;
	}
}

.btn.btn-outline-light {
	color: $dark;

	&:not(:disabled):not(.disabled):hover {
		background-color: $hover;
		color: $dark;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: $primary;
		border-color: $primary;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 0.2rem rgba($gray-400, 0.5);
	}
}

.btn.btn-outline-dark {
	border-color: $gray-600;
	color: $dark;

	&:not(:disabled):not(.disabled):hover {
		background-color: $hover;
		color: $dark;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: $primary;
		border-color: $primary;
	}

	&:focus-visible {
		background-color: $white;
	}
}

.btn.btn-outline-dark-transparent {
	border-color: $gray-600;
	color: $dark;

	&:not(:disabled):not(.disabled):hover {
		background-color: unset;
		border-color: $white;
		color: $white;
		.mask-settings {
			background-color: $white !important;
		}
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: $primary;
		border-color: $primary;
	}
}

.orca-alert {
	background-color: $gray-600;
	border-radius: $border-radius;
	color: $white;
	padding: 0.5rem 1rem;
	text-align: left;

	&-tooltip {
		bottom: -0.7rem;
		left: 50%;
		max-width: 25vw;
		position: absolute;
		transform: translateY(110%);
		width: max-content;
		z-index: 2;
	}

	&-global {
		bottom: 5rem;
		left: 50%;
		max-width: 50vw;
		position: fixed;
		transform: translateX(-50%);
		width: max-content;
		z-index: $zindex-toast;
	}

	&-full-width {
		width: 100%;
	}

	&-actionbuttons-top {
		max-width: 90%;
		position: fixed;
		top: 5.5rem;
		z-index: 2;
	}
}

.no-dropdown-marker::after {
	display: none !important;
}

.form-group {
	//   margin-bottom: $form-group-margin-bottom;
	> label {
		font-weight: $font-weight-normal;
	}
}

.form-control {
	&.ng-touched.ng-invalid {
		border-color: $danger;
	}
	&.ng-touched.ng-invalid .k-dropdown-wrap {
		border: thin solid $danger !important;
	}
}

select.form-control {
	option {
		font-size: $font-size-base;
	}
}

.desktop-container {
	@include make-container();
	width: map-get($container-max-widths, "xl");
}

.max-desktop-container-width {
	max-width: map-get($container-max-widths, "xl");
}

.modal-header {
	align-items: center;
	background-color: $primary;
	font-weight: $font-weight-bold;
	.close {
		border-color: $primary;
		outline: 0;
		padding: 0;
		padding-right: 0.5rem;
		margin: -0.5rem -1rem -0.5rem auto;
		background: transparent;
		border: 0;
	}
}

.modal-content {
	box-shadow: $dropdown-box-shadow;
}

.modal-footer {
	border-top: 0;
	float: right;
	.btn,
	lib-button .btn {
		margin: 0.5rem;
		min-width: 9rem;
		@media (min-width: map-get($container-max-widths, "sm")) {
			min-width: 12rem;
		}
	}
}

@media (min-width: 576px) {
	.modal-dialog {
		&.contact-form {
			max-width: 540px;
		}
	}
}

.modal-vertical-center {
	align-items: center;
	display: flex !important;
}

.app-link-light {
	color: $white !important;
	cursor: pointer;
	text-decoration: none !important;
	&:hover {
		color: $primary !important;
		text-decoration: underline !important;
	}
}

.app-link-dark,
.ccm-cookie-declaration a {
	color: $gray-700 !important;
	cursor: pointer;
	text-decoration: underline !important;
	.mask-settings {
		background-color: $gray-700 !important;
	}
	&:hover {
		color: $magenta !important;
		.mask-settings {
			background-color: $magenta !important;
		}
	}
}

.btn.btn-outline-primary.btn-sm.ade-tag-rounded {
	border-radius: 0.5em;

	&:disabled,
	&.disabled {
		background: $gray-200;
		border-color: $gray-200;
		color: $dark;
		opacity: 1;
		border-radius: 0;
		padding-top: 1px;
		padding-bottom: 0;
	}
}

.dashboard-shadow {
	box-shadow: 0 0 8px 0 rgba(152, 164, 174, 0.5);
}

.zindex-dropdown {
	z-index: $zindex-dropdown;
}
.zindex-sticky {
	z-index: $zindex-sticky;
}
.zindex-fixed {
	z-index: $zindex-fixed;
}
.zindex-modal-backdrop {
	z-index: $zindex-modal-backdrop;
}
.zindex-modal {
	z-index: $zindex-modal;
}
.zindex-popover {
	z-index: $zindex-popover;
}
.zindex-tooltip {
	z-index: $zindex-tooltip;
}

.close {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
	border: 0;
	background-color: transparent;
}

.card-header {
	padding: 0.75rem 1.25rem;
	margin-bottom: 0;
	background-color: #00000008;
	border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.btn-primary:hover {
	background-color: darken($primary, 5%);
	border-color: darken($primary, 10%);
}

.btn-catalog-manager:hover {
	background-color: darken($catalog-manager, 5%);
	border-color: darken($catalog-manager, 10%);
}

.card-body {
	flex: 1 1 auto;
	min-height: 1px;
	padding: 1.25rem;
}

.form-group {
	margin-bottom: 0.75rem;
}

.pt-input-label {
	padding-top: 2.5px;
}

.toggle-dropdown:active,
.toggle-dropdown:hover {
	border-color: transparent !important;
}

.w-40 {
	width: 40% !important;
}

.w-60 {
	width: 60% !important;
}

.lh-normal {
	line-height: normal;
}

.header-height {
	height: 3.86rem;
}

@each $color, $value in $theme-colors {
	.bg-#{$color}-hover:hover {
		background-color: $value !important;
	}
}
